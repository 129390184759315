import React from "react";
import { Button, Image, Icon, Container, Video } from "@atoms";
import { AppLink } from "@base";

const Resource = ({ videoUrl, linkOut, bookImage, type }) => {
  return (
    <Container className="my-10">
      <div className="flex w-full flex-col items-center justify-center">
        {linkOut?.url && bookImage?.url && (
          <AppLink className="mb-3 w-48 shadow" to={linkOut.url}>
            <Image image={bookImage} />
          </AppLink>
        )}
        {linkOut?.url && !bookImage?.url && type === "book" && (
          <AppLink className="mb-3 w-48" to={linkOut.url}>
            <Icon
              name="book"
              className="hover:text-red h-12 w-12 text-purple"
            />
          </AppLink>
        )}
        {linkOut?.url && (
          <Button color="purple" size="lg" to={linkOut.url}>
            {linkOut.text}
          </Button>
        )}
        {videoUrl && (
          <div className="my-10 w-full">
            <Video url={videoUrl} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Resource;
